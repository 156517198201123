/* Base */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
/* Styles */
import media from "styles/media";
import spacing from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { green60, white00 } from "styles/colors";
/* Components */
import Button from "components/Button";
import ShopperApprovedLogo from "public/logos/ShopperApproved.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing[4]};
  width: 100%;
  height: 48px;
  background-color: ${green60};
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  ${fontSizes[1]}
  color: ${white00};
  font-weight: bold;
  ${media.medium`
    ${fontSizes[2]}
  `}
`;

const LogoContainer = styled.span`
  display: inline-block;
  width: 100%;
  max-width: 160px;
  height: auto;
  padding-top: ${spacing[1]};
  padding-left: ${spacing[3]};

  ${media.medium`
    max-width: 192px;
  `}
`;

const PromoBanner = ({ description, buttonText, buttonUrl }) => {
  const router = useRouter();

  const handleClick = () => {
    router.push(buttonUrl);
  };

  return (
    <Container>
      {description ? (
        <>
          <Description>{description}</Description>
          {buttonText && buttonUrl && (
            <Button small secondary onClick={handleClick}>
              {buttonText}
            </Button>
          )}
        </>
      ) : (
        <Description>
          Over 250 5-star ratings on{" "}
          <LogoContainer>
            <ShopperApprovedLogo />
          </LogoContainer>
        </Description>
      )}
    </Container>
  );
};

PromoBanner.propTypes = {
  description: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
};

export default PromoBanner;
