/* Base */
import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import Head from "next/head";
import fetch from "isomorphic-unfetch";
/* Utilities */
import paths from "utils/paths";
import { withHeaderData } from "utils/addHeaderData";
/* Styles */
import media, { BREAKPOINT_LARGE, BREAKPOINT_EXTRA_LARGE } from "styles/media";
import spacing, { spacingMixin } from "styles/spacing";
import fontSizes from "styles/fontSizes";
import { black70, gray50, green60 } from "styles/colors";
/* Constants */
import {
  HEADER_PROPS_FROM_API,
  FILTER_PROPS_FROM_API,
  SAILING_DEAL_SHAPE,
  FEATURED_DEPARTURE_PORT_SHAPE,
  FEATURED_DESTINATION_SHAPE,
  CRUISELINE_SHAPE,
  FEATURED_REGION_SHAPE,
  PARTNERS_SHAPE,
  PROMOTIONAL_ITEM_SHAPE,
} from "constants/propTypes";
/* Components */
import Link from "next/link";
import Image from "next/image";
import PromoBanner from "components/pageComponents/common/PromoBanner";
import Hero from "components/pageComponents/home/Hero";
import CruiseLineLogos from "components/pageComponents/common/CruiseLineLogos";
import _SearchIcon from "public/icons/guidance.svg";
import _ListingsIcon from "public/icons/offer.svg";
import _DealsIcon from "public/icons/value-chips.svg";
import PageWrapper from "components/layout/PageWrapper";

const TopDeals = dynamic(
  () => import("components/pageComponents/home/TopDeals")
);

const ShopperApprovedFeaturedReviews = dynamic(
  () => import("components/shopperApproved/FeaturedReviews"),
  {
    ssr: false,
  }
);

const RegionImageGrid = dynamic(
  () => import("components/pageComponents/common/RegionImageGrid")
);

const FatFooter = dynamic(
  () => import("components/pageComponents/common/FatFooter")
);

const { publicRuntimeConfig } = getConfig();

const Main = styled.main`
  text-align: -moz-center;
  text-align: -webkit-center;
`;

const Content = styled.div`
  padding: ${spacing[5]} ${spacing[4]} ${spacing[5]};
  max-width: 1448px;
  ${media.large`
    padding-top: 40px;
    padding-left: ${spacing[5]};
    padding-right: ${spacing[5]};
  `}
`;

const iconStyles = css`
  height: 128px;
  margin-bottom: ${spacing[4]};
`;

const DealsIcon = styled(_DealsIcon)`
  ${iconStyles}
  align-items: center;
`;

const SearchIcon = styled(_SearchIcon)`
  ${iconStyles}
`;

const ListingsIcon = styled(_ListingsIcon)`
  ${iconStyles}
`;

const Section = styled.section`
  text-align: ${(props) =>
    props.textAlign ? props.textAlign : "center"} !important;
  position: relative;
  text-align: center;
  p {
    color: ${gray50};
    margin: 0;
  }
  ${spacingMixin}
`;

const SecondaryHeading = styled.h2`
  ${fontSizes[4]}
  ${media.medium`
    ${fontSizes[6]}
  `}
  margin: 0;
  ${spacingMixin}
  color: ${black70};
  text-align: center;
`;

const SectionHeading = styled(SecondaryHeading)`
  margin-bottom: ${spacing[5]};
  ${media.medium`
    margin-bottom: 50px;
  `};
`;

const SubHeading = styled.div`
  ${fontSizes[2]}
  ${media.medium`
    ${fontSizes[4]}
  `}
  font-weight: normal;
  margin-top: ${spacing[3]};
`;

const TertiaryHeading = styled.h3`
  ${fontSizes[3]}
  ${media.medium`
    ${fontSizes[5]}
  `}
  margin: 0 0 ${spacing[3]};
  ${media.medium`
    margin: 0 0 ${spacing[4]};
  `}
  color: ${black70};
`;

const DealsBackground = styled.div`
  position: absolute;
  width: 100%;
  z-index: -1;
`;

const WaveBackground = styled(Image)`
  display: none;
  @media (min-width: 730px) {
    display: block;
    width: 100%;
    height: 685px !important;
    object-fit: cover;
  }
  ${media.large`
    height: 697px !important;
  `}
`;

const WaveBackgroundSM = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 588px !important;
  ${media.medium`
    height: 650px !important;
  `}
  // Below is for when the subtext is 3 lines
  @media (min-width: 640px) and (max-width: 663px) {
    height: 680px !important;
  }
  @media (min-width: 730px) {
    display: none;
  }
`;

const TopDealsBR = styled.br`
  display: none;
  ${media.large`
    display: block;
  `};
`;

const HowItWorks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${media.medium`
    flex-wrap: nowrap;
  `}
`;

const HowItWorksItem = styled.div`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  ${media.medium`
    flex-basis: 33%;
    & + div {
      margin-left: ${spacing[5]};
    }
  `}
  flex-direction: column;
  ${spacingMixin}

  & p {
    ${fontSizes[2]}
  }
  margin-bottom: ${spacing[6]};
`;

const RegionContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 20px;
`;

const TestimonialsOverview = styled.p`
  ${fontSizes[4]}
  margin-top: 8px;
  ${media.medium`
    ${fontSizes[2]}
  `}
`;

const MoreTestimonialsLink = styled(Link)`
  display: inline-block;
  margin-bottom: ${spacing[4]};
  ${fontSizes[4]}
  font-weight: bold;
  color: ${green60};
  text-decoration: none;
`;

const PartnersSection = styled(Section)`
  background: rgba(223, 244, 236, 0.3);
  padding-top: ${spacing[5]};
  width: 100%;
  ${media.medium`
    padding-top: ${spacing[6]};
  `};
`;

// For articles
const OffsetSection = styled(Section)`
  margin-right: -1rem;
  ${media.large`
    margin-right: 0;
  `}
`;

const Articles = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-bottom: 1rem;
  ${media.large`
    overflow: hidden;
    margin-bottom: -1rem;
  `}
`;

const ArticleContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Article = styled.a`
  text-decoration: none;
  display: block;
  min-width: 285px;
  margin-right: 1rem;
  &:last-child {
    padding-right: 1rem;
  }
  ${media.large`
    min-width: auto;
    flex-basis: 25%;
    margin-right: 2rem;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
    }
  `}

  & > h3 {
    ${fontSizes[3]};
    color: ${black70};
    margin: 0 0 0.25rem;
  }

  & > p {
    ${fontSizes[1]};
    color: ${black70};
  }
`;

const ArticleImage = styled(Image)`
  width: 100%;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 0.25rem;
`;

const ProvidedBy = styled.p`
  text-align: center;
  ${media.mediumLarge`
  text-align: end;
  padding-right: 16px;
`}
  ${media.large`
    position: absolute;
    top: 2.5rem;
    right: 0;
    padding-right: 0;
  `}
`;

const ARTICLES = [
  {
    url: "https://travel.usnews.com/features/cruise-packing-list-the-best-things-to-pack-on-a-cruise",
    img: "/ph-travel-cruise-packing-list.webp",
    imgFallback:
      "https://www.usnews.com/object/image/00000185-cbcf-d1b4-abb5-efcfe1460001/cruise-packing-list-2023.jpg?update-time=1681834587450&size=responsive970",
    imgWidth: "970",
    imgHeight: "647",
    headline: "Cruise Packing List: 35 Essentials",
    blurb:
      "This cruise packing list includes all the essentials - plus the items you didn't know you needed.",
  },
  {
    url: "https://travel.usnews.com/features/the-best-cruise-insurance",
    img: "/ph-travel-cruise-insurance.webp",
    imgFallback:
      "https://www.usnews.com/object/image/00000184-6736-d7bb-a1ed-7fb72efa0000/cruise-insurance-stock.jpg?update-time=1668184053195&size=responsive970",
    imgWidth: "970",
    imgHeight: "647",
    headline: "Best Cruise Insurance Plans",
    blurb:
      "Consider protecting your cruise vacation by purchasing cruise insurance",
  },
  {
    url: "https://travel.usnews.com/gallery/the-ins-and-outs-of-choosing-a-cruise-ship-cabin",
    img: "/ph-travel-ship-cabin.webp",
    imgFallback:
      "https://www.usnews.com/dims4/USNEWS/13fae18/2147483647/resize/640x/quality/85/?url=http%3A%2F%2Fmedia.beam.usnews.com%2Ffa%2Fc6%2Fce9b29b042b580ca04552fc8d68f%2F160509-cruiseroom-stock.jpg",
    imgWidth: "640",
    imgHeight: "427",
    headline: "The Ins and Outs of Choosing a Cruise Ship Cabin",
    blurb: "Experts offer tips on how to select the best stateroom for you.",
  },
  {
    url: "https://travel.usnews.com/features/5-ways-cruise-lines-are-becoming-more-environmentally-friendly",
    img: "/ph-travel-environmental.webp",
    imgFallback:
      "https://www.usnews.com/dims4/USNEWS/dac8464/2147483647/resize/640x/quality/85/?url=http%3A%2F%2Fmedia.beam.usnews.com%2Fa4%2Fc6%2Fecf8b2ce49ddb4a02acab4c7d290%2F181107bestcruiselinesforthemoney-submitted.jpg",
    imgWidth: "640",
    imgHeight: "427",
    headline: "5 Ways Cruise Lines Are Becoming More Environmentally Friendly",
    blurb: "Industry considers impact as it looks to the future.",
  },
];

const Home = ({
  header,
  promotional_items,
  filters,
  filters: { cruiselines },
  featured_departure_ports: featuredDeparturePorts,
  featured_destinations: featuredDestinations,
  featured_regions: featuredRegions,
  more_cruises: moreCruises,
  page_type: pageType,
  partners,
  heroImgIndex,
  deals,
  site_settings: siteSettings,
}) => {
  const {
    promotional_banner_description: promoBannerDescription,
    promotional_banner_button_text: promoBannerButtonText,
    promotional_banner_button_url: promoBannerButtonUrl,
  } = siteSettings;

  return (
    <PageWrapper items={header}>
      <Head>
        <title>
          Best Cruise Agency for Deals &amp; Easy Online Booking | GoToSea
        </title>
        <meta
          name="description"
          content="Discover the best value cruise sailings and book with confidence on GoToSea.com."
        />
        <link rel="canonical" href={paths.home().canonicalUrl} />
        {/* eslint-disable react/no-danger */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              var utag_data = {
                "site_portal": "gotosea",
                "site_vertical": "gotosea",
                "page_type": "${pageType}",
              };
            `,
          }}
        />
        {/* eslint-enable react/no-danger */}
      </Head>
      <PromoBanner
        description={promoBannerDescription}
        buttonText={promoBannerButtonText}
        buttonUrl={promoBannerButtonUrl}
      />
      <Main>
        <Hero
          promotionalItems={promotional_items}
          filters={filters}
          heroImgIndex={heroImgIndex}
        />
        {/* These wave backgrounds need refactoring to improve performance; kludge patched for now. */}
        <DealsBackground>
          <WaveBackground
            src="/bg-wave-lg-temp.png"
            fill
            sizes={`(max-width: 730px) 1vw, (max-width: ${BREAKPOINT_LARGE}px) ${BREAKPOINT_LARGE}w,(max-width: ${BREAKPOINT_EXTRA_LARGE}px) ${BREAKPOINT_EXTRA_LARGE}w, (max-width: 1920px) 1920w, 100vw`}
            priority
            alt=""
          />
          <WaveBackgroundSM
            src="/bg-wave-sm-temp.png"
            fill
            sizes="(max-width: 730px) 730w, (min-width: 731px) 1vw"
            alt=""
          />
        </DealsBackground>

        <Content>
          <Section $spacing={6}>
            <SecondaryHeading $spacing={4} $spacingMedium={5}>
              Today&apos;s Top Deals
              <SubHeading>
                Every day, GoToSea scans thousands of cruises to find you the
                best deals. In addition to price,
                <TopDealsBR /> we consider the ship&apos;s features, itinerary
                and sail date.
              </SubHeading>
            </SecondaryHeading>
            <TopDeals sailings={deals} />
          </Section>

          <Section $spacing={5} $spacingMedium={7}>
            <SectionHeading>Why Use GoToSea</SectionHeading>
            <HowItWorks>
              <HowItWorksItem $spacing={5} $spacingMedium={1}>
                <DealsIcon />
                <TertiaryHeading>Best Values Highlighted</TertiaryHeading>
                <p>
                  We review 30,000+ cruises to help you identify the best value
                  sailings. Whether you’re looking for a budget-friendly inside
                  stateroom or a balcony suite, a three-night getaway or a
                  multiweek luxury voyage, GoToSea will connect you with the top
                  offerings.
                </p>
              </HowItWorksItem>
              <HowItWorksItem $spacing={5} $spacingMedium={1}>
                <SearchIcon />
                <TertiaryHeading>Expert Guidance</TertiaryHeading>
                <p>
                  Let us help you find the right cruise that fits your taste and
                  budget. Our powerful search features, first-timer cruise
                  travel guides and experienced support agents are here to help
                  you identify and book the perfect getaway.
                </p>
              </HowItWorksItem>
              <HowItWorksItem $spacingMedium={1}>
                <ListingsIcon />
                <TertiaryHeading>Exclusive Offers</TertiaryHeading>
                <p>
                  Book with GoToSea for special offers including extra cruise
                  cash on select voyages. Our exclusive credits are yours to
                  spend on speciality dining, spa treatments, activities and
                  more!
                </p>
              </HowItWorksItem>
            </HowItWorks>
          </Section>

          <Section $spacing={6} $spacingMedium={7}>
            <SecondaryHeading>What Our Customers Say</SecondaryHeading>
            <TestimonialsOverview>
              We are so grateful to all our customers we have helped book a
              cruise of a lifetime.
            </TestimonialsOverview>
            <MoreTestimonialsLink href="/customer-reviews">
              Read more testimonials »
            </MoreTestimonialsLink>
            <ShopperApprovedFeaturedReviews className="lg-mx6" centered />
          </Section>

          <Section $spacing={4} $spacingMedium={6}>
            <SectionHeading>
              Discover Cruise Deals by Destination
            </SectionHeading>
            {featuredRegions && featuredRegions.length > 0 && (
              <RegionContainer>
                <RegionImageGrid regions={featuredRegions} />
              </RegionContainer>
            )}
          </Section>
        </Content>

        <PartnersSection id="partners">
          <SectionHeading>Book With a Top Cruise Line</SectionHeading>
          <CruiseLineLogos partners={partners} />
        </PartnersSection>

        <Content
          css={`
            padding-top: ${spacing[6]};
            ${media.medium`
              padding-top: ${spacing[7]};
            `};
          `}
        >
          <OffsetSection $spacing={7} $spacingMedium={7}>
            <SectionHeading>
              Get Inspired: Tips and Insights For Your Upcoming Cruise
            </SectionHeading>
            <Articles>
              <ArticleContainer>
                {ARTICLES.map((article) => (
                  <Article key={article.url} href={article.url}>
                    <ArticleImage
                      src={article.img}
                      width="340"
                      height="228"
                      alt=""
                    />
                    <h3>{article.headline}</h3>
                    <p>{article.blurb}</p>
                  </Article>
                ))}
              </ArticleContainer>
            </Articles>
            <ProvidedBy>
              Content provided by <strong>U.S. News &amp; World Report</strong>
            </ProvidedBy>
          </OffsetSection>
          <FatFooter
            dividers
            destinations={featuredDestinations}
            departurePorts={featuredDeparturePorts}
            cruiselines={cruiselines}
            months={moreCruises}
          />
        </Content>
      </Main>
    </PageWrapper>
  );
};

export const getStaticProps = withHeaderData(async () => {
  try {
    const res = await fetch(
      `${publicRuntimeConfig.POSEIDON_NEXT_API}/home_page/`
    );

    // GTS-1134 default to 3 instead of 0 for production caching
    // GTS-1306 removes cookie as you cannot rely on cookies with SSG
    const currentHeroImgIndex = 3;

    const apiResponse = await res.json();
    return {
      props: {
        ...apiResponse,
        heroImgIndex: currentHeroImgIndex,
      },
      revalidate: 3600, // Set ISR revalidate to 1 hour
    };
  } catch (e) {
    // If it breaks, just show 404 page for now.
    return { notFound: true };
  }
});

Home.propTypes = {
  header: HEADER_PROPS_FROM_API,
  promotional_items: PropTypes.arrayOf(PROMOTIONAL_ITEM_SHAPE).isRequired,
  filters: FILTER_PROPS_FROM_API,
  featured_departure_ports: PropTypes.arrayOf(FEATURED_DEPARTURE_PORT_SHAPE),
  featured_destinations: PropTypes.arrayOf(FEATURED_DESTINATION_SHAPE),
  featured_regions: PropTypes.arrayOf(FEATURED_REGION_SHAPE),
  more_cruises: PropTypes.arrayOf(CRUISELINE_SHAPE),
  page_type: PropTypes.string,
  partners: PropTypes.arrayOf(PARTNERS_SHAPE),
  heroImgIndex: PropTypes.number,
  deals: PropTypes.arrayOf(SAILING_DEAL_SHAPE),
  site_settings: PropTypes.object,
};

export default Home;
